import React, { useEffect, useState } from "react";
import { Grid, Stack, Box, Typography } from "@mui/material";
import moment from "moment";
import momentkh from "@thyrith/momentkh";
import { useQuery } from "@apollo/client";
import { GET_CASH_IN_DETAIL_BY_DAY } from "../../Schema/Report";
import {
  getKhmerNumber,
  getKhmerMonth,
  currencyFormat,
} from "../../Function/GetKhmerNumber";
import wingdingImage from "../../Assets/tacteing-header.PNG";
import logoCompany from "../../Assets/logo.svg";

export default function CashInReportByDay({ dateSelected }) {
  const [khmerDateString, setKhmerDateString] = useState("");

  const [tableData, setTableData] = useState([]);

  const { data } = useQuery(GET_CASH_IN_DETAIL_BY_DAY, {
    variables: {
      date: moment(dateSelected)?.format("YYYY-MM-DD"),
    },
    onCompleted: ({ getCashInByDayReport }) => {
      setTableData(getCashInByDayReport);
    },
    onError: (error) => {
      console.log(error.message);
    },
  });

  // console.log("tableData", tableData);

  useEffect(() => {
    let khMoment = momentkh(moment);
    setKhmerDateString(khMoment()?.toLunarDate("ថ្ងៃW dN ខែm ឆ្នាំa e ព.ស b"));
  }, []);

  return (
    <div className="setup-company-container">
      <Grid container spacing={1}>
        <Grid item xs={4}>
          <Box className="logo-container">
            <img src={logoCompany} className="logo-company" />
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Stack direction="row" justifyContent="center">
            <Box>
              <div className="summary-center-kh">ព្រះរាជាណាចក្រកម្ពុជា</div>
              <div className="summary-center-kh">ជាតិ សាសនា ព្រះមហាក្សត្រ</div>
              <div className="tackteng-font">
                <img
                  alt="Image"
                  src={wingdingImage}
                  className="image-tackteng"
                />
              </div>
            </Box>
          </Stack>
          <Box className="summary-center-kh" sx={{ marginTop: "60px" }}>
            <div>របាយការណ៍សាច់ប្រាក់ចូល</div>
            <div>
              ថ្ងៃទី
              {getKhmerNumber(moment(dateSelected)?.format("DD"))} ខែ
              {getKhmerMonth(moment(dateSelected).format("M"))} ឆ្នាំ​
              {getKhmerNumber(moment(dateSelected)?.format("YYYY"))}
            </div>
          </Box>
        </Grid>
        <Grid
          item
          xs={4}
          sx={{ display: "flex", justifyContent: "right", marginTop: "100px" }}
        >
          {/* <Stack direction="row" spacing={1}>
            <Typography className="number-title-customerbyday">លេខ៖</Typography>
            <Typography className="number-item-customerbyday">
              ...........................
            </Typography>
          </Stack> */}
        </Grid>
      </Grid>
      <div className="company-table-container">
        <table className="company-table">
          <tr style={{ backgroundColor: "#eaeefa" }}>
            <th rowSpan={2} style={{ width: "5%" }}>
              ល.រ
            </th>
            <th rowSpan={2} style={{ width: "35%" }}>
              អតិថិជន
            </th>
            <th colSpan={3}>ប្រភេទកម្ចី</th>
          </tr>
          <tr style={{ backgroundColor: "#eaeefa" }}>
            <th style={{ width: "15%" }}>បង់រំលស់</th>
            <th style={{ width: "15%" }}>កម្ចី</th>
            <th style={{ width: "15%" }}>បញ្ចាំ</th>
          </tr>

          

          {tableData?.details_cash_in?.map((row, index) => (
            <tr key={index}>
              <td>{getKhmerNumber(index + 1)}</td>

              <td style={{ textAlign: "left" }}>
                {row?.first_name + " " + row?.last_name}
              </td>
              <td>
                {currencyFormat(row?.amount_and_type?.filter((e) => e?.type_of_loan === "Installments")?.reduce((sum, item) => sum + item.loan_amount, 0))}
              </td>
              <td>
                {currencyFormat(row?.amount_and_type?.filter((e) => e?.type_of_loan === "Loan")?.reduce((sum, item) => sum + item.loan_amount, 0))}
              </td>
              
              <td>
                {currencyFormat(row?.amount_and_type?.filter((e) => e?.type_of_loan === "Pawn")?.reduce((sum, item) => sum + item.loan_amount, 0))}
              </td>
            </tr>
          ))}
          <tr style={{ backgroundColor: "#eaeefa", color: "red" }}>
            <th colSpan={2}>សរុប</th>
            <th>{currencyFormat(tableData?.total_installments_amount)}</th>
            <th>{currencyFormat(tableData?.total_bussiness_loan_amount)}</th>
            <th>{currencyFormat(tableData?.total_pawn_amount)}</th>
          </tr>
        </table>
      </div>
      <Grid container spacing={2}>
        <Grid item xs={3.5}>
          {/* <div className="loan-footer-title">{`សៀមរាប ថ្ងៃទី${getKhmerNumber(
            moment().format("DD")
          )} ខែ${getKhmerMonth(moment().format("M"))} ឆ្នាំ${getKhmerNumber(
            moment().format("YYYY")
          )}`}</div>
          <div className="loan-footer-name">អ្នកទទួលប្រាក់</div>
          <div className="loan-footer-name" style={{ marginTop: "60px" }}>
            ........................
          </div> */}
        </Grid>

        <Grid item xs={2.5} sx={{ marginTop: "40px" }}>
          {/* <div className="loan-footer-name">អ្នកបញ្ចេញប្រាក់</div>
          <div className="loan-footer-name" style={{ marginTop: "60px" }}>
            ........................
          </div> */}
        </Grid>

        <Grid item xs={6} sx={{ marginTop: "10px" }}>
          <div className="loan-footer-title">{khmerDateString}</div>
          <div className="loan-footer-title">{`សៀមរាប ថ្ងៃទី${getKhmerNumber(
            moment().format("DD")
          )} ខែ${getKhmerMonth(moment().format("M"))} ឆ្នាំ${getKhmerNumber(
            moment().format("YYYY")
          )}`}</div>

          <Stack direction="row" spacing={6} sx={{marginLeft: "75px"}}>
            <Box>
              {/* <div className="loan-footer-name">អ្នកអនុម័ត</div>
              <div className="loan-footer-name" style={{ marginTop: "60px" }}>
                ........................
              </div> */}
            </Box>
            <Box>
              <div className="loan-footer-name">អ្នករៀបចំ</div>
              <div className="loan-footer-name" style={{ marginTop: "60px" }}>
                ........................
              </div>
            </Box>
          </Stack>
        </Grid>
      </Grid>
    </div>
  );
}
