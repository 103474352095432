import { gql } from "@apollo/client";

export const GET_LAON_ON_CUSTOMERHAND = gql`
  query GetReportPrincipal($month: Int, $year: Int) {
    getReportPrincipal(month: $month, year: $year) {
      totalPrincipal {
        number
        total_amount
        type
      }
      type_of_loan
    }
  }
`;

export const GET_LAONSTATUS_ON_CUSTOMERHAND = gql`
  query GetReportStatusCostomer($month: Int, $year: Int) {
    getReportStatusCostomer(month: $month, year: $year) {
      type
      totalStatus {
        status
        number
        amount
      }
    }
  }
`;

export const GET_DAILY_CUSTOMER = gql`
  query GetReportCostomerPayment($paymentStatus: String, $date: Date) {
    getReportCostomerPayment(payment_status: $paymentStatus, date: $date) {
      name
      code
      interest
      payment
      total_payment
      late_month
      payment_date
      late_day
      phone
      last_payment_day
      last_principal_for_late_difficult
      last_interest_for_late_difficult
      type_of_loan
    }
  }
`;

export const GET_NEW_CUSTOMER_REPORT = gql`
  query GetNewCustomerReport($month: Int, $year: Int) {
    getNewCustomerReport(month: $month, year: $year) {
      loan_id
      first_name
      last_name
      type_of_loan
      loan_date
      loan_amount
    }
  }
`;

export const GET_REST_OF_MONEY_REPORT = gql`
  query GetReportRestOfMoney($year: Int, $month: Int) {
    getReportRestOfMoney(year: $year, month: $month) {
      loan_id
      customer_name
      loan_date
      type_of_loan
      rest_of_money
    }
  }
`;

export const GET_CUSTOMER_BY_DAY_REPORT = gql`
  query GetReportCostomerByDay($day: Date) {
    getReportCostomerByDay(day: $day) {
      loans {
        _id
        customer_id {
          _id
          first_name
          last_name
        }
        loan_id
        loan_plan {
          _id
          month
          interest
          over_due_penalty
          remark
        }
        loan_amount
        loan_date
      }
      pawns {
        _id
        customer_id {
          _id
          first_name
          last_name
        }
        loan_id
        loan_plan {
          _id
          month
          interest
          over_due_penalty
          remark
        }
        loan_amount
        loan_date
      }
      installments {
        _id
        customer_id {
          _id
          first_name
          last_name
        }
        loan_id
        loan_plan {
          _id
          month
          interest
          over_due_penalty
          remark
        }
        loan_amount
        loan_date
      }
    }
  }
`;

export const GET_CASH_OUT_DETAIL = gql`
  query GetCashOutDetail($year: String!) {
    getCashOutDetail(year: $year) {
      details_cash_out {
        amount_and_type {
          loan_amount
          type_of_loan
        }
        first_name
        last_name
        loan_date
        total_amount
      }
      total_amount
      total_bussiness_loan_amount
      total_installments_amount
      total_pawn_amount
    }
  }
`;

export const GET_CASH_IN_DETAIL = gql`
  query GetCashInDetail($year: String!, $loanTypeId: String!) {
    getCashInDetail(year: $year, loan_type_id: $loanTypeId) {
      details_cash_in {
        loan_id
        first_name
        last_name
        amount_paid
        principal
        interest
        penalty
      }
      total_amount_paid
      total_interest
      total_penalty
      total_principal
    }
  }
`;

export const GET_CASH_OUT_DETAIL_BY_DAY = gql`
  query GetCashOutByDayReport($date: String) {
    getCashOutByDayReport(date: $date) {
      details_cash_out {
        amount_and_type {
          type_of_loan
          loan_amount
        }
        first_name
        last_name
      }
      total_bussiness_loan_amount
      total_installments_amount
      total_pawn_amount
    }
  }
`;

export const GET_CASH_IN_DETAIL_BY_DAY = gql`
  query GetCashInByDayReport($date: String) {
    getCashInByDayReport(date: $date) {
      details_cash_in {
        first_name
        last_name
        amount_and_type {
          loan_amount
          type_of_loan
        }
      }
      total_bussiness_loan_amount
      total_installments_amount
      total_pawn_amount
    }
  }
`;

export const GET_CASH_OUT_DETAIL_BY_MONTH = gql`
  query GetCashOutDetailByMonth($year: String!, $month: String!) {
    getCashOutDetailByMonth(year: $year, month: $month) {
      total_amount
      total_bussiness_loan_amount
      total_pawn_amount
      total_installments_amount
      details_cash_out {
        loan_date
        amount_and_type {
          loan_amount
          type_of_loan
        }
        total_amount
      }
    }
  }
`;

export const GET_CASH_IN_DETAIL_BY_MONTH = gql`
  query GetCashInDetailByMonth($year: String!, $month: String!) {
    getCashInDetailByMonth(year: $year, month: $month) {
      total_amount
      total_bussiness_loan_amount
      total_pawn_amount
      total_installments_amount
      details_cash_in {
        loan_date
        amount_and_type {
          loan_amount
          type_of_loan
        }
        total_amount
      }
    }
  }
`;

export const GET_CUSTOMER_TO_PAY = gql`
query GetCustomerToPayReport($startDate: String, $endDate: String) {
  getCustomerToPayReport(start_date: $startDate, end_date: $endDate) {
    total_amount
    total_interest
    total_principal
    loan_data {
      principal
      interest
      total_amount
      detail {
        customer_name
        loan_id
        principal
        interest
        total_amount
        month_num
        to_pay_date
        customer_tell
      }
    }
    pawn_data {
      principal
      interest
      total_amount
      detail {
        customer_name
        loan_id
        principal
        interest
        total_amount
        month_num
        to_pay_date
        customer_tell
      }
    }
    installment_data {
      principal
      interest
      total_amount
      detail {
        customer_name
        loan_id
        principal
        interest
        total_amount
        month_num
        to_pay_date
        customer_tell
      }
    }
  }
}
`;
