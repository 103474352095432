import React, { useEffect, useState } from "react";
import { Grid, Stack, Box } from "@mui/material";
import moment from "moment";
import momentkh from "@thyrith/momentkh";
import { useQuery } from "@apollo/client";
import { getFormattedPhoneNum } from "../../Function/GetKhmerNumber";
import { GET_CUSTOMER_TO_PAY } from "../../Schema/Report";
import {
    getKhmerNumber,
    getKhmerMonth,
    currencyFormat,
    getKhmerDay,
} from "../../Function/GetKhmerNumber";
import wingdingImage from "../../Assets/tacteing-header.PNG";
import logoCompany from "../../Assets/logo.svg";

export default function CustomerToPay({ dateSelected }) {
    const [khmerDateString, setKhmerDateString] = useState("");
    const [tableData, setTableData] = useState([]);

    const { data, refetch } = useQuery(GET_CUSTOMER_TO_PAY, {
        variables: {
            startDate: moment(dateSelected)?.format("YYYY-MM-DD"),
            endDate: moment(dateSelected)?.format("YYYY-MM-DD")
        },
        onCompleted: ({ getCustomerToPayReport }) => {
            setTableData(getCustomerToPayReport);
            console.log("getCustomerToPayReport::=>", getCustomerToPayReport);
        },
        onError: (error) => {
            console.log("error::", error.message);
        },
    });

    // const loanData = tableData?.filter((data) => data?.type_of_loan === "Loan");
    // const pawnData = tableData?.filter((data) => data?.type_of_loan === "Pawn");
    // const installmentData = tableData?.filter(
    //     (data) => data?.type_of_loan === "Installments"
    // );

    // console.log("loanData::", loanData, pawnData, installmentData)

    useEffect(() => {
        // let khMoment = momentkh(moment);
        // setKhmerDateString(
        //     khMoment(dateSelected)?.toLunarDate("ថ្ងៃW dN ខែm ឆ្នាំa e ព.ស b")
        // );
        refetch()
    }, [dateSelected]);

    return (
        <div className="setup-company-container">
            <Grid container spacing={1}>
                <Grid item xs={4}>
                    <Box className="logo-container">
                        <img src={logoCompany} className="logo-company" />
                    </Box>
                </Grid>
                <Grid item xs={4}>
                    <Stack direction="row" justifyContent="center">
                        <Box>
                            <div className="summary-center-kh">ព្រះរាជាណាចក្រកម្ពុជា</div>
                            <div className="summary-center-kh">ជាតិ សាសនា ព្រះមហាក្សត្រ</div>
                            <div className="tackteng-font">
                                <img
                                    alt="Image"
                                    src={wingdingImage}
                                    className="image-tackteng"
                                />
                            </div>
                        </Box>
                    </Stack>
                    <Box className="summary-center-kh" sx={{ marginTop: "60px" }}>
                        របាយការណ៍ផែនការណ៍អតិថិជនត្រូវបង់ប្រាក់ {`ខែ${getKhmerMonth(moment(dateSelected)?.format("M"))} ឆ្នាំ${getKhmerNumber(moment(dateSelected)?.format("YYYY"))}`}
                    </Box>
                    <Box className="loan-footer-title" sx={{ marginTop: "10px" }}>
                        {/* {`ថ្ងៃ${getKhmerDay(
                            moment(dateSelected).utc().format("dddd")
                        )} ទី${getKhmerNumber(
                            moment(dateSelected).utc().format("DD")
                        )} ខែ${getKhmerMonth(
                            moment(dateSelected).utc().format("M")
                        )} ឆ្នាំ${getKhmerNumber(
                            moment(dateSelected).utc().format("YYYY")
                        )}`} */}
                        {/* {`ខែ${getKhmerMonth(moment(dateSelected)?.format("M"))} ឆ្នាំ${getKhmerNumber(moment(dateSelected)?.format("YYYY"))}`} */}
                    </Box>
                </Grid>
                <Grid item xs={4}></Grid>
            </Grid>
            <div className="company-table-container">
                <table className="company-table">
                    <tr style={{ backgroundColor: "#eaeefa" }}>
                        <th></th>
                        <th>ឈ្មោះ</th>
                        <th>លេខកូដ</th>
                        <th>ប្រាក់ដើម</th>
                        <th>ការប្រាក់</th>
                        <th>ប្រាក់ត្រូវបង់សរុប</th>
                        <th>ចំ.ខែ</th>
                        <th>ថ្ងៃត្រូវបង់ចុងក្រោយ</th>
                        <th>លេខទូរស័ព្ទ</th>
                    </tr>

                    {tableData?.loan_data?.detail?.length === 0 ? null : (
                        <tr style={{ backgroundColor: "#eaeefa" }}>
                            <th className="first-col" colSpan={9}>
                                កម្ចី
                            </th>
                            {/* <th></th> */}
                        </tr>
                    )}

                    {tableData?.loan_data?.detail?.map((row, index) => (
                        <tr key={index}>
                            <td>{index + 1}</td>
                            <td style={{ textAlign: "left" }}>{row?.customer_name}</td>
                            <td>{row?.loan_id}</td>
                            <td>{row?.principal ? currencyFormat(row?.principal) : ""}</td>
                            <td>{row?.interest ? currencyFormat(row?.interest) : ""}</td>
                            <td>
                                {row?.total_amount ? currencyFormat(row?.total_amount) : ""}
                            </td>
                            <th>{row?.month_num}</th>
                            <td>
                                {row?.to_pay_date
                                    ? moment(row?.to_pay_date).utc().format("DD-MMM-YYYY")
                                    : ""}
                            </td>
                            <td>{getFormattedPhoneNum(row?.customer_tell)}</td>
                        </tr>
                    ))}

                    {tableData?.pawn_data?.detail?.length === 0 ? null : (
                        <tr style={{ backgroundColor: "#eaeefa" }}>
                            <th className="first-col" colSpan={9}>
                                បញ្ចាំ
                            </th>
                            {/* <th></th> */}
                        </tr>
                    )}

                    {tableData?.pawn_data?.detail?.map((row, index) => (
                        <tr key={index}>
                            <td>{index + 1}</td>
                            <td style={{ textAlign: "left" }}>{row?.customer_name}</td>
                            <td>{row?.loan_id}</td>
                            <td>{row?.principal ? currencyFormat(row?.principal) : ""}</td>
                            <td>{row?.interest ? currencyFormat(row?.interest) : ""}</td>
                            <td>
                                {row?.total_amount ? currencyFormat(row?.total_amount) : ""}
                            </td>
                            <th>{row?.month_num}</th>
                            <td>
                                {row?.to_pay_date
                                    ? moment(row?.to_pay_date).utc().format("DD-MMM-YYYY")
                                    : ""}
                            </td>
                            <td>{getFormattedPhoneNum(row?.customer_tell)}</td>
                        </tr>
                    ))}

                    {tableData?.installment_data?.detail?.length === 0 ? null : (
                        <tr style={{ backgroundColor: "#eaeefa" }}>
                            <th className="first-col" colSpan={9}>
                                បង់រំលោះ
                            </th>
                            {/* <th></th> */}
                        </tr>
                    )}

                    {tableData?.installment_data?.detail?.map((row, index) => (
                        <tr key={index}>
                            <td>{index + 1}</td>
                            <td style={{ textAlign: "left" }}>{row?.customer_name}</td>
                            <td>{row?.loan_id}</td>
                            <td>{row?.principal ? currencyFormat(row?.principal) : ""}</td>
                            <td>{row?.interest ? currencyFormat(row?.interest) : ""}</td>
                            <td>
                                {row?.total_amount ? currencyFormat(row?.total_amount) : ""}
                            </td>
                            <th>{row?.month_num}</th>
                            <td>
                                {row?.to_pay_date
                                    ? moment(row?.to_pay_date).utc().format("DD-MMM-YYYY")
                                    : ""}
                            </td>
                            <td>{getFormattedPhoneNum(row?.customer_tell)}</td>
                        </tr>
                    ))}

                    <tr style={{ backgroundColor: "#eaeefa" }}>
                        <th colSpan={3}>សរុប</th>
                        <th>{tableData?.total_principal ? currencyFormat(tableData?.total_principal) : ""}</th>
                        <th>{tableData?.total_interest ? currencyFormat(tableData?.total_interest) : ""}</th>
                        <th>{tableData?.total_amount ? currencyFormat(tableData?.total_amount) : ""}</th>

                    </tr>

                </table>
            </div>

            <Grid container spacing={2} sx={{ marginTop: "10px" }}>
                <Grid item xs={6}>
                    <div className="loan-footer-title">បានត្រួតពិនិត្យ និងឯកភាព</div>
                    <div className="loan-footer-name">ជ.អ្នកគ្រប់គ្រង</div>
                    <div className="loan-footer-name">អ្នកគ្រប់គ្រងសាច់ប្រាក់</div>
                    <div className="loan-footer-name" style={{ marginTop: "60px" }}>
                        {/* ខូវ ស៊ូខេង */}
                        ........................
                    </div>
                </Grid>

                <Grid item xs={6} sx={{ marginTop: "10px" }}>
                    <div className="loan-footer-title">{khmerDateString}</div>
                    <div className="loan-footer-title">{`សៀមរាប ថ្ងៃទី${getKhmerNumber(
                        moment().format("DD")
                    )} ខែ${getKhmerMonth(
                        moment().format("M")
                    )} ឆ្នាំ${getKhmerNumber(moment().format("YYYY"))}`}</div>
                    <div className="loan-footer-name">អ្នកធ្វើតារាង</div>
                    <div className="loan-footer-name" style={{ marginTop: "60px" }}>
                        {/* អុន ដានី */}
                        ........................
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};
